
import { Report } from '@/wms/domain/reports/ReportConfig';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { FindFiltersByReport } from '@/wms/application/reports/search/FindFiltersByReport';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { ReportFieldFilters } from '@/wms/domain/reports/ReportFieldFilters';
import AccountGroupSearch from '@/core/components/shared/AccountGroupSearch.vue';
import GroupSearch from '@/core/components/shared/GroupSearch.vue';
import CostCenterSearch from '@/core/components/shared/CostCenterSearch.vue';
import LedgerAccountSearch from '@/core/components/shared/LedgerAccountSearch.vue';
import StoreSearch from '@/core/components/shared/StoreSearch.vue';

@Component({
  name: 'ReportForm',
  components: {
    AccountGroupSearch,
    GroupSearch,
    CostCenterSearch,
    LedgerAccountSearch,
    StoreSearch
  }
})
export default class ReportForm extends Vue {
  @Inject(TYPES.REPORT_FIND_FILTERS_BY_TYPE)
  readonly reportFindFilters!: FindFiltersByReport;

  @Prop() reportList!: Report[];
  @Prop() selectedReportType!: string | null;
  @Prop() handleGenerate!: (filters: Record<string, any>) => Promise<string>;
  @Prop() updateReport!: (report: Report) => Promise<Report>;

  fields: ReportFieldFilters[] = [];
  isLoading = false;

  formData: Record<string, any> = {};

  @Watch('selectedReportType')
  onSelectedReportTypeChange() {
    if (!this.selectedReportType) return;
    this.findFilters(this.selectedReportType);
  }

  async findFilters(type: string) {
    try {
      this.isLoading = true;
      this.fields = await this.reportFindFilters.internalExecute(type);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async handleSubmit() {
    // Se debe procesar el payload para convertir todos aquellos con la función de castValue
    // Si el campo es nulo, entonces se debe asignar el valor por defecto que se encuentra en el campo defaultValueIfNull

    // Lo asignamos a un nuevo objeto para no modificar el original y evitar que el usuario se alarme o deba volver a llenar el formulario
    const payload = Object.assign({}, this.formData);

    for (const field of this.fields) {
      if (!payload[field.name]) {
        payload[field.name] = this.castValue(field.defaultValueIfNull, field.validateAs);
      } else {
        payload[field.name] = this.castValue(payload[field.name], field.validateAs);
      }
    }

    try {
      this.isLoading = true;
      await this.handleGenerate(payload);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  castValue(value: string, validateAs: string): any {
    switch (validateAs) {
      case 'number':
        return Number(value);
      case 'boolean':
        return value.toLowerCase() === 'true';
      case 'date':
        return new Date(value);
      default:
        return value;
    }
  }
}
